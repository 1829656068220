/* @import '../../../styles/propertySets.css'; */

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.searchResultSummary {
  /* @apply --marketplaceH4FontStyles; */
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  /* @apply --marketplaceH4FontStyles; */
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;
}

.sortyBy {
  /* @apply --marketplaceSmallFontStyles; */
  /* font-weight: var(--fontWeightRegular); */
  padding: 8px 8px 8px 0;
}
.mainPanelWishlist {
  /* @apply --marketplaceH2FontStyles; */
  line-height: 20px;
  padding-right: 10px;
}
